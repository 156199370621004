.menu-dropdown-space {
    position: absolute;
    top: calc(var(--navbar-height) + 2px);
    width: calc(100% - 30px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2px;
}