.login-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    min-height: 100px;
    width: 300px;
}

.login-component p {
    font-family: var(--senex-font);
    font-size: 18pt;
}

.login-entry-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    gap: 10px;
}

.login-entry-fields input {
    flex: 1;
    padding: 10px;
    width: 80%;
    margin: 5px;
    border-radius: 10px;
    font-family: var(--senex-font);
    border: 1px solid rgba(0,0,0,0.2);
    font-size: 14pt;
}

.login-submit-button {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,0.2);
    font-size: 14pt;
    margin-top: 10px;
    width: 50%;
}

.login-submit-button:hover {
    background-color: black;
    color: white;
}

.login-submit-button:disabled {
    cursor: wait;
}

.login-alternate-mode-button {
    margin-top: 5px;
    color: var(--senex-purple) !important;
    font-style: italic;
    transition: 0.3s ease;
}

.login-alternate-mode-button:hover {
    color: black !important;
}

.submit-error-message {
    font-style: italic;
    transition: 0.3s ease;
    color: darkred;
}

.account-request-success-msg {
    font-style: italic;
    transition: 0.3s ease;
    color: darkgreen;
}

.login-cookies-warning {
    width: 300px;
    font-size: 10pt;
    font-style: italic;
    text-align: left;
    margin: 0;
}
