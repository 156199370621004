/* Senex Specific information & params */
:root {
  --senex-beige: #FEF9E7;
  --senex-purple: #9C61F8;
  --senex-grey: rgba(0,0,0,0.2);
  --senex-grey-opaque: #f0f0f0;
  --senex-font: 'Avenir';
  --navbar-height: 60px;
  --content-max-w: 900px;
}

html {
  /* Ensure smooth scrolling app-wide */
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Avenir', sans-serif; /* We can ensure all text will be in Avenir unless otherwise noted. I just kinda think it looks cool. */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--senex-beige); /* We can ensure any page overflows will maintain the same background color (i.e. no unsightly whitespace) */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Avenir',
    monospace;
}

