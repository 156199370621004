.chat-item {
    display: flex;
    background-color: white;
    border: 1px solid var(--senex-grey);
    flex-direction: row;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.chat-item-user-sec {
    padding: 10px;
    font-size: 12pt;
    font-weight: 600;
    width: 50px;
    align-self: center;
}

.chat-item-user-sec .you-user {
    color: black;
}

.chat-item-user-sec .senex-user {
    color: var(--senex-purple);
}

.chat-item-data-sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: 1px solid var(--senex-grey);
}

.chat-item-metadata-top-sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 10px;
    gap: 8px;
}

.chat-item-metadata-top-sec .chat-item-metadata-item {
    font-style: italic;
    font-size: 10pt;
}

.chat-item-core-content {
    padding: 10px;
    text-align: left;
    font-size: 11pt;
}

.chat-item-assistant-change-text {
    font-style: italic;
}
