.spinner-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 100vh;
}

.spinner-body {
    border: 16px solid rgba(0,0,0,0.2);
    border-top: 16px solid var(--senex-purple); /* Blue */
    border-radius: 50%;
    width: 90px;
    height: 90px;
}

.spinner-loading-text {
    font-family: var(--senex-font);
    font-size: 24pt;
    font-style: italic;
}

.senex-logo-large {
    margin-top: 20px;
    max-height: 35vmin;
    width: 40%;
    pointer-events: none;
    margin: 0;
}