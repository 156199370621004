/* 

Master Styling file for use in any component

Mainly will use for shared animations & transitions

Will import this in whichever files it's needed in

*/

/* Keyframes for spinners & fading */

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Repeating for other browsers to ensure cross-compatibility */

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Master Animations */
.fade-in-1s {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

.fade-in-2s {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
}

.fade-in-2s-flicker {
    animation: fadeIn 2s linear infinite;
    -webkit-animation: fadeIn 2s linear infinite;
    -moz-animation: fadeIn 2s linear infinite;
    -o-animation: fadeIn 2s linear infinite;
    -ms-animation: fadeIn 2s linear infinite;
}

.fade-in-3s-flicker {
    animation: fadeIn 3s linear infinite;
    -webkit-animation: fadeIn 3s linear infinite;
    -moz-animation: fadeIn 3s linear infinite;
    -o-animation: fadeIn 3s linear infinite;
    -ms-animation: fadeIn 3s linear infinite;
}

.spin-300ms {
    animation: spin 300ms linear infinite;
    -webkit-animation: spin 300ms linear infinite;
    -moz-animation: spin 300ms linear infinite;
    -o-animation: spin 300ms linear infinite;
    -ms-animation: spin 300ms linear infinite;
}