.spinner-space-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.spinner-body-small {
    border: 12px solid rgba(0,0,0,0.2);
    border-top: 12px solid var(--senex-purple); /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.spinner-loading-text-small {
    font-family: var(--senex-font);
    font-size: 16pt;
    font-style: italic;
}

.spinner-loading-text-sec-small {
    font-family: var(--senex-font);
    font-size: 16pt;
    font-style: italic;
}