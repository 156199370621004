/* Buttons CSS */

.solid-button {
    flex: 1;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 10px;
    padding: 10px;
    font-family: var(--senex-font);
    border: none;
    box-shadow: 1px 1px 5px 1px var(--senex-grey);
}

.text-button {
    flex: 1;
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    color: black;
    font-family: var(--senex-font);
}

.hamburger-menu {
    font-size: 12pt;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid black;
    transition: 0.3s ease;
}

.hamburger-menu:hover {
    background-color: black;
    color: white;
}

.open {
    background-color: black;
    color: white;
}

/* Hamburger Content Button */

.hamburger-content-button {
    font-family: var(--senex-font);
    cursor: pointer;
    background-color: var(--senex-grey-opaque);
    color: black;
    border: none;
    padding: 12px;
    transition: 0.1s ease;
    font-size: calc(10pt + 1vmin);
    width: stretch;
    box-shadow: 1px 1px 10px 1px var(--senex-grey);
}

.hamburger-content-button:hover {
    background-color: var(--senex-purple);
    color: white;
}