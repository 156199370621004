.assistant-selector-space {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.assistant-selector-space button {
    flex: 1;
    height: 60px;
    padding: none;
    border: 3px solid transparent;
}

.assistant-selector-space button:hover {
    border: 3px solid var(--senex-grey);
}

.assistant-selector-space .selected {
    border: 3px solid var(--senex-purple);
}
