.welcome-page-full {
    height: 100vh;
    text-align: center;
    background-color: var(--senex-beige);
}

.welcome-page-content {
    background-color: var(--senex-beige);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
}

.senex-logo-large {
    margin-top: 20px;
    max-height: 35vmin;
    width: 40%;
    pointer-events: none;
    margin: 0;
}

.header-text {
    font-size: calc(16pt + 2vmin);
    max-width: 80%;
}

.secondary-text {
    font-size: calc(9pt + 1vmin);
    max-width: 65%;
    margin: 0;
}