.chat-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 25px;
    width: 100%;
}

.text-entry-space {
    background-color: transparent;
    border: 1px solid var(--senex-grey);
    border-radius: 10px;
    width: stretch;
    height: 100px;
    resize: none;
    padding: 10px;
    font-family: var(--senex-font);
    overflow: auto;
    outline: none;
    font-size: 14pt;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.chat-submit-button {
    border: 1px solid var(--senex-grey);
    font-size: 14pt;
    width: 120px;
    transition: 0.3s ease;
}

.chat-submit-button:hover {
    background-color: black;
    color: white
}

.chat-window-openai-disclaimer {
    font-size: 10pt;
    font-style: italic;
    text-align: left;
    margin: 0;
}
