/* Moving all styling into modules */

.page-full-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    /* height: 100vh; */
}

.page-elements-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--content-max-w);
    width: stretch;
    /* background-color: yellow; */
}

.page-header {
    /* width: 100%; */
    width: stretch;
    max-width: var(--content-max-w);
    display: flex;
    position: fixed;
    /* background-color: rebeccapurple; */
    background-color: var(--senex-beige);
    box-shadow: 1px 1px 5px 1px var(--senex-beige);
    top: 0;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 20px; */
    align-items: center;
    height: var(--navbar-height);
    
}

.page-content {
    /* height: 2000px; */
    height: fit-content;
    min-height: calc(100vh - var(--navbar-height));
    margin-top: var(--navbar-height);
    /* overflow: scroll; */
    padding-left: 15px;
    padding-right: 15px;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: aquamarine */
}

.user-options-space {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: calc(8pt + 1vmin);
    margin-right: 15px;
}

.senex-logo {
    margin-left: 15px;
    width: auto;
    pointer-events: none;
}

/* Dynamic Sizing for Logo based on Screen width */

@media screen and (max-width: 400px) {
    .logo-size {
        height: 25%;
    }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
    .logo-size {
        height: 30%;
    }
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
    .logo-size {
        height: 35%;
    }
}

@media screen and (min-width: 1000px) {
    .logo-size {
        height: 40%;
    }
}

/* Buttons */

.signout-button:hover {
    background-color: black;
    color: white
}

/* Session */

.session-metadata-space {
    text-align: left;
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
}

.session-metadata-space .opt-label {
    font-weight: 600;
}

.session-data-full-space {
    margin-top: 20px;
}